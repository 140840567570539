import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import PortfolioContext from '../../context/context';
import Loading from '../Loading/Loading';

const LoadableComponent = Loadable({
  loader: () => import('../Goo/Goo'),
  loading: Loading,
});

const Header = (props) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const { theme } = props;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron" style={theme}>
      {/* eslint-disable global-require */}
      <img alt="site logo" className="logo" src={require('../../images/favicon.png')} />
      {/* eslint-enable global-require */}
      <LoadableComponent />
      {/* <Goo/> */}
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{name || 'Your Name'}</span>
            <br />
            {subtitle || "I'm the Unknown Developer."}
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={1000}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
      {/* eslint-disable global-require */}
      <img
        alt="small wave with a green, teal, and blue gradient."
        className="about-wave"
        src={require('../../images/wave.png')}
      />
      {/* eslint-enable global-require */}
    </section>
  );
};

Header.propTypes = {
  theme: PropTypes.node,
};

export default Header;
